/* @v3.3 - chequear analisis del documento que se adjunta pero no lee su contenido */
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import { ChangeEvent, FormEvent, KeyboardEvent, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Send } from 'feather-icons-react';
import { convertFileToAttachment } from 'helpers/utils';
import AttachmentPreview from 'components/common/AttachmentPreview';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {
  faImage,
  faPaperPlane,
  faPaperclip,
  faTrash,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import { Form, Dropdown, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import mammoth from 'mammoth';
import { parseISO, format } from 'date-fns';
import * as pdfjsLib from 'pdfjs-dist';

const LoadingText = ({ text = "Processing" }) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prev => {
        if (prev.length >= 3) return '';
        return prev + '.';
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="text-muted fs-8">
      {text}{dots}
    </div>
  );
};

interface FileProcessingResult {
  content: string;
  metadata?: {
    author?: string;
    createdAt?: string;
    modifiedAt?: string;
    pageCount?: number;
    [key: string]: any;
  };
}

const ChatWidgetFooter = () => {
  const [messageText, setMessageText] = useState('');
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<FileProcessingResult | null>(null);
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const { sentMessage, isProcessingInput } = useChatWidgetContext();

  const TypingIndicator = () => (
    <>
      {[0, 1, 2].map((i) => (
        <div
          key={i}
          className=""
          style={{ animationDelay: `${i * 0.2}s` }}
        />
      ))}
        <Spinner animation="border" size="sm" />
    </>
  );

  useEffect(() => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = 
      `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
  }, []);

  useEffect(() => {
    if (!isProcessingInput && isProcessingFile) {
      setFileAttachment(null);
      setFileContent(null);
      setIsProcessingFile(false);
    }
  }, [isProcessingInput]);

  const processPDF = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdfDoc = await loadingTask.promise;
      let fullText = '';
      
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item.str)
          .join(' ');
        fullText += `\n--- Page ${i} ---\n` + pageText;
      }

      return {
        content: fullText.trim(),
        metadata: {
          pageCount: pdfDoc.numPages,
          documentInfo: 'PDF Document'
        }
      };
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const processExcel = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer);
      let fullText = '';
  
      workbook.SheetNames.forEach(sheetName => {
        const worksheet = workbook.Sheets[sheetName];
        fullText += `\n--- Sheet: ${sheetName} ---\n`;
        fullText += XLSX.utils.sheet_to_csv(worksheet, { blankrows: false });
      });
  
      return {
        content: fullText.trim(),
        metadata: {
          sheets: workbook.SheetNames.length,
          type: 'Excel Document'
        }
      };
    } catch (error) {
      console.error('Error processing Excel:', error);
      throw error;
    }
  };

  const processWord = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      
      return {
        content: result.value,
        metadata: {
          type: 'Word Document',
          messages: result.messages
        }
      };
    } catch (error) {
      console.error('Error processing Word:', error);
      throw error;
    }
  };

  const processTextFile = async (file: File): Promise<FileProcessingResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          content: e.target?.result as string,
          metadata: {
            type: 'Text Document',
            size: file.size,
            lastModified: new Date(file.lastModified).toISOString()
          }
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const processFile = async (file: File): Promise<FileProcessingResult | null> => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    
    try {
      switch (fileType) {
        case 'pdf':
          return await processPDF(file);
        case 'xlsx':
        case 'xls':
          return await processExcel(file);
        case 'docx':
        case 'doc':
          return await processWord(file);
        case 'txt':
        case 'csv':
        case 'json':
          return await processTextFile(file);
        default:
          throw new Error(`Unsupported file type: ${fileType}`);
      }
    } catch (error) {
      console.error('Error processing file:', error);
      return null;
    }
  };

  const handleFileChange = async ({
    target: { files }
  }: ChangeEvent<HTMLInputElement>) => {
    if (files && files[0]) {
      const file = files[0];
      setFileAttachment(file);
      setIsProcessingFile(true);
      
      try {
        const result = await processFile(file);
        if (result) {
          setFileContent(result);
        }
      } catch (error) {
        console.error('Error processing file:', error);
      } finally {
        setIsProcessingFile(false);
      }
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (messageText || (fileAttachment && fileContent)) {
      let finalMessage = messageText;

      if (fileAttachment && fileContent) {
        //finalMessage = `${messageText}\n\nArchivo adjunto: ${fileAttachment.name}`;
        /* const metadata en chatbox */
        const metadata = Object.entries(fileContent.metadata || {})
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n');
        finalMessage = `${messageText}\n\n<documents>\n<document index="1">\n<source>${fileAttachment.name}</source>\n<metadata>\n${metadata}\n</metadata>\n<document_content>\n${fileContent.content}\n</document_content>\n</document>\n</documents>`;
        /* end metadata en chatbox */
        setIsProcessingFile(true);
      }

      sentMessage({
        message: messageText, // changed this line
        attachments: {
          images: imageAttachments.map(imageAttachment =>
            URL.createObjectURL(imageAttachment)
          ),
          file: fileAttachment
            ? convertFileToAttachment(fileAttachment, finalMessage)
            : undefined
        }
      });

      setMessageText('');
      setImageAttachments([]);
    }
  };
  
  return (
    <form onSubmit={handleSubmit}>
      {fileAttachment && (
        <div className={classNames({ 'mb-2': fileAttachment })}>
          <AttachmentPreview
            attachment={convertFileToAttachment(fileAttachment)}
            size="xl"
            handleRemove={() => {
              setFileAttachment(null);
              setFileContent(null);
              setIsProcessingFile(false);
            }}
          />
          {isProcessingFile && <LoadingText />}
        </div>
      )}

      <div className="d-flex align-items-center gap-2">
        <div className="d-flex align-items-center flex-1 gap-3 border rounded-pill px-4">
          <ReactTextareaAutosize
            className="chat-textarea form-control outline-none border-0 scrollbar resize-none"
            placeholder="Ask Hazbot..."
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
              }
            }}
            minRows={1}
            maxRows={5}
            disabled={isProcessingInput || isProcessingFile}
          />
        </div>
        {isProcessingInput && <TypingIndicator />}
        {/*<div>
          <Button className="p-0" disabled={isProcessingFile}>
            <label
              className="text-body-quaternary fs-9 cursor-pointer"
              htmlFor="widgetAttachments"
            >
              <FontAwesomeIcon icon={faPaperclip} transform="down-1" />
            </label>
          </Button>
          <Form.Control
            className="d-none"
            type="file"
            id="widgetAttachments"
            accept=".pdf,.docx,.doc,.xlsx,.xls,.txt,.csv,.json"
            onChange={handleFileChange}
          />
        </div>*/}

        <Button
          className="p-0 border-0 send-btn"
          type="submit"
          disabled={isProcessingInput || isProcessingFile}
        >
             <Send size={20} color="red" />
        </Button>
      </div>
    </form>
  );
};

export default ChatWidgetFooter;
/*
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import { ChangeEvent, FormEvent, KeyboardEvent, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Send } from 'feather-icons-react';
import { convertFileToAttachment } from 'helpers/utils';
import AttachmentPreview from 'components/common/AttachmentPreview';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {
  faImage,
  faPaperPlane,
  faPaperclip,
  faTrash,
  faDownload,
  faMicrophone,
  faMicrophoneSlash,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { Form, Dropdown, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import mammoth from 'mammoth';
import { parseISO, format } from 'date-fns';
import * as pdfjsLib from 'pdfjs-dist';

// Interfaces
interface SpeechRecognitionEvent extends Event {
  results: SpeechRecognitionResultList;
  resultIndex: number;
  error: any;
}

interface SpeechRecognitionResultList {
  length: number;
  item(index: number): SpeechRecognitionResult;
  [index: number]: SpeechRecognitionResult;
}

interface SpeechRecognitionResult {
  isFinal: boolean;
  length: number;
  item(index: number): SpeechRecognitionAlternative;
  [index: number]: SpeechRecognitionAlternative;
}

interface SpeechRecognitionAlternative {
  transcript: string;
  confidence: number;
}

interface SpeechRecognition extends EventTarget {
  continuous: boolean;
  interimResults: boolean;
  lang: string;
  onerror: (event: SpeechRecognitionEvent) => void;
  onend: () => void;
  onresult: (event: SpeechRecognitionEvent) => void;
  start(): void;
  stop(): void;
  abort(): void;
}

type SpeechRecognitionConstructor = {
  new (): SpeechRecognition;
  prototype: SpeechRecognition;
};

declare global {
  interface Window {
    SpeechRecognition: SpeechRecognitionConstructor;
    webkitSpeechRecognition: SpeechRecognitionConstructor;
  }
}

interface FileProcessingResult {
  content: string;
  metadata?: {
    author?: string;
    createdAt?: string;
    modifiedAt?: string;
    pageCount?: number;
    [key: string]: any;
  };
}

// Componentes auxiliares
const LoadingText = ({ text = "Processing" }) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prev => {
        if (prev.length >= 3) return '';
        return prev + '.';
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="text-muted fs-8">
      {text}{dots}
    </div>
  );
};

const TypingIndicator = () => (
  <>
    {[0, 1, 2].map((i) => (
      <div
        key={i}
        className=""
        style={{ animationDelay: `${i * 0.2}s` }}
      />
    ))}
    <Spinner animation="border" size="sm" />
  </>
);

const ChatWidgetFooter = () => {
  // Estados existentes
  const [messageText, setMessageText] = useState('');
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<FileProcessingResult | null>(null);
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  
  // Nuevos estados para Speech-to-Text
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState<SpeechRecognition | null>(null);
  const [speechError, setSpeechError] = useState<string | null>(null);

  const { sentMessage, isProcessingInput } = useChatWidgetContext();

  // Configuración de PDF.js
  useEffect(() => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = 
      `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
  }, []);

  // Configuración de Speech Recognition
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      if (SpeechRecognition) {
        const recognition = new SpeechRecognition();
        recognition.continuous = true;
        recognition.interimResults = true;
        recognition.lang = 'es-ES';

        recognition.onresult = (event) => {
          const transcript = Array.from(event.results)
            .map(result => result[0])
            .map(result => result.transcript)
            .join('');
          
          setMessageText(prevText => {
            // Si el texto anterior termina en espacio o está vacío, no añadimos espacio
            const separator = prevText.endsWith(' ') || prevText === '' ? '' : ' ';
            return prevText + separator + transcript;
          });
        };

        recognition.onerror = (event) => {
          console.error('Speech recognition error', event.error);
          setSpeechError(event.error);
          setIsListening(false);
        };

        recognition.onend = () => {
          setIsListening(false);
        };

        setRecognition(recognition);
      }
    }
  }, []);

  // Limpieza de estados
  useEffect(() => {
    if (!isProcessingInput && isProcessingFile) {
      setFileAttachment(null);
      setFileContent(null);
      setIsProcessingFile(false);
    }
  }, [isProcessingInput]);

  // Funciones de procesamiento de archivos
  const processPDF = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdfDoc = await loadingTask.promise;
      let fullText = '';
      
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item.str)
          .join(' ');
        fullText += `\n--- Page ${i} ---\n` + pageText;
      }

      return {
        content: fullText.trim(),
        metadata: {
          pageCount: pdfDoc.numPages,
          documentInfo: 'PDF Document'
        }
      };
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const processExcel = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer);
      let fullText = '';
  
      workbook.SheetNames.forEach(sheetName => {
        const worksheet = workbook.Sheets[sheetName];
        fullText += `\n--- Sheet: ${sheetName} ---\n`;
        fullText += XLSX.utils.sheet_to_csv(worksheet, { blankrows: false });
      });
  
      return {
        content: fullText.trim(),
        metadata: {
          sheets: workbook.SheetNames.length,
          type: 'Excel Document'
        }
      };
    } catch (error) {
      console.error('Error processing Excel:', error);
      throw error;
    }
  };

  const processWord = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      
      return {
        content: result.value,
        metadata: {
          type: 'Word Document',
          messages: result.messages
        }
      };
    } catch (error) {
      console.error('Error processing Word:', error);
      throw error;
    }
  };

  const processTextFile = async (file: File): Promise<FileProcessingResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          content: e.target?.result as string,
          metadata: {
            type: 'Text Document',
            size: file.size,
            lastModified: new Date(file.lastModified).toISOString()
          }
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const processFile = async (file: File): Promise<FileProcessingResult | null> => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    
    try {
      switch (fileType) {
        case 'pdf':
          return await processPDF(file);
        case 'xlsx':
        case 'xls':
          return await processExcel(file);
        case 'docx':
        case 'doc':
          return await processWord(file);
        case 'txt':
        case 'csv':
        case 'json':
          return await processTextFile(file);
        default:
          throw new Error(`Unsupported file type: ${fileType}`);
      }
    } catch (error) {
      console.error('Error processing file:', error);
      return null;
    }
  };

  // Manejadores de eventos
  const handleFileChange = async ({
    target: { files }
  }: ChangeEvent<HTMLInputElement>) => {
    if (files && files[0]) {
      const file = files[0];
      setFileAttachment(file);
      setIsProcessingFile(true);
      
      try {
        const result = await processFile(file);
        if (result) {
          setFileContent(result);
        }
      } catch (error) {
        console.error('Error processing file:', error);
      } finally {
        setIsProcessingFile(false);
      }
    }
  };

  const toggleListening = () => {
    if (!recognition) {
      setSpeechError('Speech recognition is not supported in this browser');
      return;
    }
    
    if (isListening) {
      recognition.stop();
      setIsListening(false);
    } else {
      setSpeechError(null);
      recognition.start();
      setIsListening(true);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (messageText || (fileAttachment && fileContent)) {
      let finalMessage = messageText;

      if (fileAttachment && fileContent) {
        const metadata = Object.entries(fileContent.metadata || {})
          .map(([key, value]) => `${key}: ${value}`)
          .join('\n');
        finalMessage = `${messageText}\n\n<documents>\n<document index="1">\n<source>${fileAttachment.name}</source>\n<metadata>\n${metadata}\n</metadata>\n<document_content>\n${fileContent.content}\n</document_content>\n</document>\n</documents>`;
        setIsProcessingFile(true);
      }

      sentMessage({
        message: messageText,
        attachments: {
          images: imageAttachments.map(imageAttachment =>
            URL.createObjectURL(imageAttachment)
          ),
          file: fileAttachment
            ? convertFileToAttachment(fileAttachment, finalMessage)
            : undefined
        }
      });

      setMessageText('');
      setImageAttachments([]);
    }
  };

  // Render
  return (
    <form onSubmit={handleSubmit} className="chat-widget-footer">
      {fileAttachment && (
        <div className={classNames({ 'mb-2': fileAttachment })}>
          <AttachmentPreview
            attachment={convertFileToAttachment(fileAttachment)}
            size="xl"
            handleRemove={() => {
              setFileAttachment(null);
              setFileContent(null);
              setIsProcessingFile(false);
            }}
          />
          {isProcessingFile && <LoadingText />}
        </div>
      )}

      <div className="d-flex align-items-center gap-2">
        <div className="d-flex align-items-center flex-1 gap-3 border rounded-pill px-4">
          <ReactTextareaAutosize
            className="chat-textarea form-control outline-none border-0 scrollbar resize-none"
            placeholder="Ask Hazbot..."
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
              }
            }}
            minRows={1}
            maxRows={5}
            disabled={isProcessingInput || isProcessingFile}
          />
        </div>

        {speechError && (
          <div className="text-danger position-absolute bottom-100 start-0 small">
            {speechError}
          </div>
        )}

        <Button
          className={`p-2 border-0 rounded-circle ${isListening ? 'bg-danger text-white' : ''}`}
          type="button"
          onClick={toggleListening}
          disabled={isProcessingInput || isProcessingFile}
          title={isListening ? 'Stop listening' : 'Start listening'}
        >
          <FontAwesomeIcon 
            icon={isListening ? faMicrophone : faMicrophoneSlash}
            className="fs-5"
            spin={isListening}
          />
        </Button>

        <Button
          className="p-0 border-0 send-btn"
          type="submit"
          disabled={isProcessingInput || isProcessingFile}
        >
          <Send size={20} color="cyan" />
        </Button>
      </div>

      {isProcessingInput && <TypingIndicator />}
    </form>
  );
};

export default ChatWidgetFooter; */